import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useLocaleStore = defineStore("locale", () => {
  // region Properties
  const _locale = ref("pl");
  // endregion

  // region Getters
  const locale = computed(() => _locale.value);

  // endregion

  // region Actions

  function setLocale(newLocale: string): void {
    _locale.value = newLocale;
  }
  // endregion

  return { locale, setLocale };
});
