import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type ISecurityElement from "@/types/ISecurityElement";
import type ISecurityProducer from "@/types/ISecurityProducer";

export const useSecurityDetailsModalStore = defineStore(
  "securityDetailsModalStore",
  () => {
    // region Properties
    const _showModal = ref(false);
    const _selectedElement = ref({} as ISecurityElement);
    const _selectedProducer = ref({} as ISecurityProducer);

    // endregion

    // region Getters
    const isModalShow = computed((): boolean => _showModal.value);
    // const boardSet = computed(() => _boardSet);
    const selectedSecurityElement = computed(
      (): ISecurityElement => _selectedElement.value
    );
    const selectedSecurityProducer = computed(
      (): ISecurityProducer => _selectedProducer.value
    );
    // endregion

    // region Actions
    function showModal(): void {
      _showModal.value = true;
    }

    function hideModal(): void {
      _showModal.value = false;
    }

    function setElementAndProducer(
      boardElement: ISecurityElement,
      producer: ISecurityProducer
    ) {
      _selectedElement.value = boardElement;
      _selectedProducer.value = producer;
    }
    // endregion

    return {
      showModal,
      hideModal,
      isModalShow,
      selectedSecurityElement,
      selectedSecurityProducer,
      setElementAndProducer,
    };
  }
);
