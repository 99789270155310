import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type IBoardElement from "@/types/IBoardElement";
import type IProducer from "@/types/IProducer";

export const useElementDetailsModalStore = defineStore(
  "elementDetailsModalStore",
  () => {
    // region Properties
    const _showModal = ref(false);
    const _selectedElement = ref({} as IBoardElement);
    const _selectedProducer = ref({} as IProducer);

    // endregion

    // region Getters
    const isModalShow = computed(() => _showModal.value);
    // const boardSet = computed(() => _boardSet);
    const selectedElement = computed(() => _selectedElement.value);
    const selectedProducer = computed(() => _selectedProducer.value);
    // endregion

    // region Actions
    function showModal(): void {
      _showModal.value = true;
    }

    function hideModal(): void {
      _showModal.value = false;
    }

    function setElementAndProducer(
      boardElement: IBoardElement,
      producer: IProducer
    ) {
      _selectedElement.value = boardElement;
      _selectedProducer.value = producer;
    }
    // endregion

    return {
      showModal,
      hideModal,
      isModalShow,
      selectedElement,
      selectedProducer,
      setElementAndProducer,
    };
  }
);
