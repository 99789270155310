import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import type ISwitchboardCategory from "@/types/ISwitchboardCategories";

export const useCategoriesStore = defineStore("categories", () => {
  // region Properties
  const _isExpandedCategory = ref(false);
  const _categories: Array<ISwitchboardCategory> = reactive([]);
  // endregion

  // region Getters

  const isExpandedCategory = computed((): boolean => {
    return _isExpandedCategory.value;
  });

  const categories = computed((): Array<ISwitchboardCategory> => {
    return _categories;
  });

  // endregion

  // region Actions
  function hideCategories(): void {
    for (const category of _categories) {
      category._isExpanded = false;
    }

    setIsExpandedCategory(false);
  }

  function setCategories(categories: Array<ISwitchboardCategory>): void {
    while (_categories.length) {
      _categories.pop();
    }

    for (const category of categories) {
      const _isExpanded = false;
      const preparedCategory = { ...category, _isExpanded };

      _categories.push(preparedCategory);
    }
  }

  function setIsExpandedCategory(state: boolean): void {
    _isExpandedCategory.value = state;
  }
  // endregion

  return {
    categories,
    setCategories,
    isExpandedCategory,
    setIsExpandedCategory,
    hideCategories,
  };
});
