import { defineStore } from "pinia";
import { useElementDetailsModalStore } from "@/stores/elementDetailsModal";
import { useConfirmationModalStore } from "@/stores/confirmationModal";
import { useProductInfoModalStore } from "@/stores/productInfoModal";
import { useSecurityDetailsModalStore } from "@/stores/securityDetailsModal";
import { useSummaryModalsStore } from "@/stores/summaryModals";
import { useAccessoriesInfoModalStore } from "@/stores/accessoriesInfoModal";

export const useModalsStore = defineStore("modalsStore", () => {
  // region Properties
  const accessoriesInfoModalStore = useAccessoriesInfoModalStore();
  const elementDetailsModalStore = useElementDetailsModalStore();
  const confirmationModalStore = useConfirmationModalStore();
  const productInfoModalStore = useProductInfoModalStore();
  const securityDetailsModalStore = useSecurityDetailsModalStore();
  const summaryModalsStore = useSummaryModalsStore();
  // endregion

  // region Actions
  function hideAllModals(): void {
    accessoriesInfoModalStore.hideModal();
    elementDetailsModalStore.hideModal();
    confirmationModalStore.hideModal();
    productInfoModalStore.hideModal();
    securityDetailsModalStore.hideModal();
    summaryModalsStore.hideOfferModal();
    summaryModalsStore.hideConfigurationModal();
  }

  // endregion

  return {
    hideAllModals,
  };
});
