import { defineStore } from "pinia";
import { ref, reactive, computed } from "vue";
import type ISelectOption from "@/types/ISelectOption";
import type ISecurityProducer from "@/types/ISecurityProducer";
import type ISecurityProducers from "@/types/ISecurityProducers";
import { useConfigurationStore } from "@/stores/configuration";
import type IBoardSet from "@/types/IBoardSet";
import Functions from "@/plugins/Functions";

export const useSecurityElementsStore = defineStore(
  "securityElementsStore",
  () => {
    // region Properties
    const _othersProducers: Array<ISecurityProducer> = reactive([]);
    const _photovoltaicProducers: Array<ISecurityProducer> = reactive([]);

    const _suggestedElements: Array<IBoardSet> = reactive([]);

    const _preparedOtherProducers: Array<ISelectOption> = reactive([]);
    const _preparedPhotovoltaicProducers: Array<ISelectOption> = reactive([]);

    const _loadingData = ref(false);
    const _securityGridsLoaded = ref(false);
    const _securityGridsLoading = ref(false);

    const configurationStore = useConfigurationStore();
    // endregion

    // region Getters
    const loadingData = computed((): boolean => _loadingData.value);
    const securityGridsLoaded = computed(
      (): boolean => _securityGridsLoaded.value
    );
    const securityGridsLoading = computed(
      (): boolean => _securityGridsLoading.value
    );

    const othersProducers = computed(
      (): Array<ISecurityProducer> => _othersProducers
    );

    const photovoltaicProducers = computed(
      (): Array<ISecurityProducer> => _photovoltaicProducers
    );

    const preparedOtherProducers = computed(
      (): Array<ISelectOption> => _preparedOtherProducers
    );

    const preparedPhotovoltaicProducers = computed(
      (): Array<ISelectOption> => _preparedPhotovoltaicProducers
    );

    const preparedSecurityElements = computed((): ISelectOption[] => {
      const tempElements: Array<ISelectOption> = [];

      if (configurationStore.configuration.selectedSecurityOtherProducer) {
        configurationStore.configuration.selectedSecurityOtherProducer.fuses.forEach(
          (element: any) => {
            const preparedElement = {
              label: element.name,
              value: element,
            };

            tempElements.push(preparedElement);
          }
        );
      }

      if (
        configurationStore.configuration.selectedSecurityPhotovoltaicProducer
      ) {
        configurationStore.configuration.selectedSecurityPhotovoltaicProducer.fuses.forEach(
          (element: any) => {
            const preparedElement = {
              label: element.name,
              value: element,
            };

            tempElements.push(preparedElement);
          }
        );
      }

      return tempElements;
    });

    const securityElementsInvalid = computed((): boolean => {
      let isInvalid = false;

      for (const grid of configurationStore.securityGrids) {
        for (const element of grid.elements) {
          if (
            element.securityElement &&
            element.noAvailableForSelectedProducer
          ) {
            isInvalid = true;

            break;
          }
        }
      }

      return isInvalid;
    });

    // endregion

    // region Actions
    function setSecurityGridsLoaded(isLoaded: boolean): void {
      _securityGridsLoaded.value = isLoaded;
    }

    function setSecurityGridsLoading(loding: boolean): void {
      _securityGridsLoading.value = loding;
    }
    function setLoadingData(state: boolean): void {
      _loadingData.value = state;
    }
    function setSecurityProducers(producers: ISecurityProducers): void {
      while (_othersProducers.length) {
        _othersProducers.pop();
      }

      while (_photovoltaicProducers.length) {
        _photovoltaicProducers.pop();
      }

      if (!producers || !producers.producer) {
        return;
      }

      if (producers.producer.others?.compatible) {
        for (const producer of producers.producer.others.compatible) {
          _othersProducers.push(producer);

          if (producer.is_default_for_ordinary_fuses) {
            configurationStore.setSelectedSecurityOtherProducer(producer);
          }
        }
      }

      if (producers.producer.photovoltaic?.compatible) {
        for (const producer of producers.producer.photovoltaic.compatible) {
          _photovoltaicProducers.push(producer);

          if (producer.is_default_for_photovoltaic_fuses) {
            configurationStore.setSelectedPhotovoltaicOtherProducer(producer);
          }
        }
      }

      if (
        producers.producer.photovoltaic?.compatible?.length &&
        !configurationStore.configuration.selectedSecurityPhotovoltaicProducer
      ) {
        configurationStore.setSelectedPhotovoltaicOtherProducer(
          producers.producer.photovoltaic.compatible[0]
        );
      }

      _prepareSecurityProducersToSelect();

      _prepareSuggestedElements(producers);

      configurationStore.addSuggestedSecurityElementsToGrid(_suggestedElements);
    }

    function _prepareSuggestedElements(producers: ISecurityProducers) {
      while (_suggestedElements.length) {
        _suggestedElements.pop();
      }

      if (producers.producer.others?.suggested?.length) {
        for (const producer of producers.producer.others.suggested) {
          for (const element of producer.fuses) {
            const preparedElement: IBoardSet = {
              uId: Functions.uId(),
              securityElement: element,
              securityProducer: producer,
            };

            _suggestedElements.push(preparedElement);
          }
        }
      }

      if (producers.producer.photovoltaic?.suggested?.length) {
        for (const producer of producers.producer.photovoltaic.suggested) {
          for (const element of producer.fuses) {
            const preparedElement: IBoardSet = {
              uId: Functions.uId(),
              securityElement: element,
              securityProducer: producer,
            };

            _suggestedElements.push(preparedElement);
          }
        }
      }
    }

    function _prepareSecurityProducersToSelect(): void {
      while (_preparedOtherProducers.length) {
        _preparedOtherProducers.pop();
      }

      while (_preparedPhotovoltaicProducers.length) {
        _preparedPhotovoltaicProducers.pop();
      }

      _othersProducers.forEach((producer: ISecurityProducer) => {
        const preparedElement = {
          label: producer.name,
          value: producer,
        };

        _preparedOtherProducers.push(preparedElement);
      });

      _photovoltaicProducers.forEach((producer: ISecurityProducer) => {
        const preparedElement = {
          label: producer.name,
          value: producer,
        };

        _preparedPhotovoltaicProducers.push(preparedElement);
      });
    }

    // endregion

    return {
      othersProducers,
      photovoltaicProducers,
      preparedOtherProducers,
      preparedPhotovoltaicProducers,
      preparedSecurityElements,
      setSecurityProducers,
      setLoadingData,
      loadingData,
      securityGridsLoaded,
      setSecurityGridsLoaded,
      securityGridsLoading,
      setSecurityGridsLoading,
      securityElementsInvalid,
    };
  }
);
