import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useSummaryModalsStore = defineStore("summaryModals", () => {
  // region Properties
  const _showOfferModal = ref(false);
  const _showConfigurationModal = ref(false);
  const _successConfigurationModal = ref(false);
  const _failConfigurationModal = ref(false);
  const _failedRequestIsOffer = ref(false);
  const _sendingConfiguration = ref(false);

  const show400Message = ref(false);

  // endregion

  // region Getters
  const isOfferModalShow = computed(() => _showOfferModal.value);
  const isConfigurationModalShow = computed(
    () => _showConfigurationModal.value
  );
  const isSuccessConfigurationModalShow = computed(
    () => _successConfigurationModal.value
  );
  const isFailedConfigurationModalShow = computed(
    () => _failConfigurationModal.value
  );

  const failedRequestIsOffer = computed(
    (): boolean => _failedRequestIsOffer.value
  );
  const isSendingConfiguration = computed(
    (): boolean => _sendingConfiguration.value
  );
  // endregion

  // region Actions
  function showOfferModal(): void {
    _showOfferModal.value = true;
  }

  function showSuccessConfigurationModal(): void {
    _successConfigurationModal.value = true;
  }

  function showConfigurationModal(): void {
    _showConfigurationModal.value = true;
  }

  function showFailedConfigurationModal(requestIsOffer: boolean): void {
    _failedRequestIsOffer.value = requestIsOffer;

    _failConfigurationModal.value = true;
  }

  function hideOfferModal(): void {
    _showOfferModal.value = false;
  }

  function hideConfigurationModal(): void {
    _showConfigurationModal.value = false;
  }

  function hideSuccessConfigurationModal(): void {
    _successConfigurationModal.value = false;
  }

  function hideFailedConfigurationModal(): void {
    _failConfigurationModal.value = false;
  }

  function setSendingConfiguration(state: boolean): void {
    _sendingConfiguration.value = state;
  }

  // endregion

  return {
    isConfigurationModalShow,
    isOfferModalShow,
    showOfferModal,
    hideOfferModal,
    showConfigurationModal,
    hideConfigurationModal,
    showSuccessConfigurationModal,
    hideSuccessConfigurationModal,
    isSuccessConfigurationModalShow,
    hideFailedConfigurationModal,
    showFailedConfigurationModal,
    isFailedConfigurationModalShow,
    failedRequestIsOffer,
    isSendingConfiguration,
    setSendingConfiguration,
    show400Message,
  };
});
