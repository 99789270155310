import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { PageType } from "@/types/PageType";
import { i18n } from "@/main";

export const useNavigatorStore = defineStore("navigator", () => {
  const _page = ref(0);
  const _subPage = ref(false);
  const _router = useRouter();
  const _isPageLoading = ref(false);
  const _isSecurityPageSkipped = ref(false);

  // region Getters
  const pageId = computed((): number => _page.value);
  const currentPage = computed((): string => PageType[pageId.value]);
  const isPageLoading = computed((): boolean => _isPageLoading.value);
  const isSecurityPageSkipped = computed(
    (): boolean => _isSecurityPageSkipped.value
  );

  function isSubPage(): boolean {
    return _subPage.value;
  }

  // endregion

  // region Actions
  function goToPage(pageName: string, locale?: string): void {
    _router.push({
      name: pageName,
      params: { locale: locale ? locale : i18n.global.locale.value },
    });
  }

  function goToCurrentPage(): void {
    _router.push({
      name: PageType[_page.value],
      params: { locale: i18n.global.locale.value },
    });
  }

  function goToNextPage(): void {
    _page.value++;

    _router.push({
      name: PageType[_page.value],
      params: { locale: i18n.global.locale.value },
    });
  }

  function goToPreviousPage(): void {
    if (_page.value > 0) {
      _page.value--;

      _router.push({
        name: PageType[_page.value],
        params: { locale: i18n.global.locale.value },
      });
    }
  }

  function setPage(newPage: number): void {
    _page.value = newPage;
  }

  function setIsInfoPage(subPage: boolean): void {
    _subPage.value = subPage;
  }

  function setPageIsLoading(isLoading: boolean): void {
    _isPageLoading.value = isLoading;
  }

  function setSecurityPageIsSkipped(skipped: boolean): void {
    _isSecurityPageSkipped.value = skipped;
  }
  // endregion

  return {
    goToNextPage,
    goToPreviousPage,
    setPage,
    goToPage,
    goToCurrentPage,
    isSubPage,
    setIsInfoPage,
    pageId,
    currentPage,
    isPageLoading,
    setPageIsLoading,
    isSecurityPageSkipped,
    setSecurityPageIsSkipped,
  };
});
