import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "vue-toast-notification/dist/theme-sugar.css";

import App from "./App.vue";
import router from "./router";

import translationsEN from "./assets/locales/en.json";
import translationsPL from "./assets/locales/pl.json";
import translationsDE from "./assets/locales/de.json";

const messages = {
  en: translationsEN,
  pl: translationsPL,
  de: translationsDE,
};

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: "pl", // set locale
  fallback: "pl", // set locale
  messages,
  strategy: "prefix_except_default",
});

const app = createApp(App);

app
  .use(i18n)
  .provide("$t", app.config.globalProperties.$t)
  .use(createPinia())
  .use(router);

app.mount("#app");
