<template>
  <ul class="language-dropdown-container">
    <b-nav-item-dropdown
      :text="formatLang($i18n.locale)"
      right
      class="language-dropdown list-unstyled border-dark"
    >
      <b-dropdown-item
        v-for="locale in filteredLocales"
        :key="`locale-${locale}`"
        @click="selectLang($i18n, locale)"
      >
        {{ formatLang(locale) }}
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown
      :text="formatLang($i18n.locale, true)"
      right
      class="language-dropdown-mobile list-unstyled border-dark"
      data-bs-toggle="collapse"
      data-bs-target="#lang-collapse"
    >
    </b-nav-item-dropdown>
  </ul>
  <CollapseMenuBottom
    v-if="isSwitchboardPage"
    class="mobile-collapse-lang"
    :id="'lang-collapse'"
    :title="$t('chooseLang')"
  >
    <template #content>
      <div
        v-for="(locale, key) in filteredLocales"
        :key="key"
        class="option"
        data-bs-toggle="collapse"
        data-bs-target="#lang-collapse"
        @click="selectLang($i18n, locale)"
      >
        {{ formatLang(locale) }}
      </div>
    </template>
  </CollapseMenuBottom>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useLocaleStore } from "@/stores/locale";
import { useNavigatorStore } from "@/stores/navigator";
import CollapseMenuBottom from "@/components/Layout/CollapseMenuBottom.vue";
import { useConfigurationStore } from "@/stores/configuration";
import { useCategoriesStore } from "@/stores/categories";
import { BNavItemDropdown, BDropdownItem } from "bootstrap-vue-3";
import { useRouter } from "vue-router";

const navigatorStore = useNavigatorStore();
const localeStore = useLocaleStore();
const configurationStore = useConfigurationStore();
const categoriesStore = useCategoriesStore();
const i18n = useI18n();
const router = useRouter();

const isSwitchboardPage = computed((): boolean => navigatorStore.pageId === 0);
const filteredLocales = computed(() =>
  i18n.availableLocales.filter((locale: string) => {
    return locale !== i18n.locale.value;
  })
);

function goToHomePage(locale: string): void {
  router.push(`/${locale}`);
}

function selectLang($i18n: any, locale: string): void {
  if (isSwitchboardPage.value) {
    $i18n.locale = locale;

    localeStore.setLocale(locale);
    categoriesStore.hideCategories();
    configurationStore.clearConfiguration();

    goToHomePage(locale);
  }
}

function formatLang(locale: string, mobile = false): string {
  switch (locale) {
    case "pl":
      return !mobile ? "Polski" : "Pl";
    case "en":
      return !mobile ? "English" : "En";
    case "de":
      return !mobile ? "Deutsch" : "De";
    default:
      return !mobile ? "Polski" : "Pl";
  }
}
</script>

<style lang="scss">
.language-dropdown-container {
  padding: 0;
  margin: 0;
}

.language-dropdown,
.language-dropdown-mobile {
  padding-bottom: 15px;
  padding-top: 37px;
  border: none;

  &:focus {
    border: none;
    background: transparent;
  }

  .btn-group {
    .btn {
      .btn-content {
        text-transform: uppercase;
      }

      &.show {
        &.dropdown-toggle {
          &:after {
            transform: rotate(180deg);
          }
        }
      }

      &.dropdown-toggle {
        line-height: 1;

        &:after {
          transition: 0.3s;
          margin-left: 12px;
        }
      }
    }

    .dropdown-menu {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      top: calc(100% + 15px);

      li {
        display: flex;
        justify-content: flex-end;
        text-transform: uppercase;

        .dropdown-item {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 15px 0;
          width: fit-content;
          text-transform: uppercase;
          border-bottom: 1px solid $dark;

          &:hover {
            background-color: transparent;
            color: $dark;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 19px 0;

    .btn-group {
      .dropdown-menu {
        display: none;
      }
    }
  }
}

.language-dropdown-mobile,
.mobile-collapse-lang {
  display: none;
}

@media (max-width: 768px) {
  .language-dropdown {
    display: none;
  }

  .language-dropdown-mobile,
  .mobile-collapse-lang {
    display: block;
  }
}
</style>
