import { defineStore } from "pinia";
import { computed, ref } from "vue";
import type { Ref } from "vue";

export const useConfirmationModalStore = defineStore(
  "confirmationModalStore",
  () => {
    // region Properties
    const _showModal: Ref<boolean> = ref(false);
    const _pageName: Ref<string | null> = ref(null);
    // endregion

    // region Getters
    const isModalShow = computed(() => _showModal.value);
    const pageName = computed(() => _pageName.value);

    // endregion

    // region Actions
    function showModal(pageType?: string): void {
      if (pageType) {
        _pageName.value = pageType;
      } else {
        _pageName.value = null;
      }
      _showModal.value = true;
    }

    function hideModal(): void {
      _showModal.value = false;
    }
    // endregion

    return {
      showModal,
      hideModal,
      isModalShow,
      pageName,
    };
  }
);
