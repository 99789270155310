<template>
  <ModalComponent
    id="confirmationModal"
    :show="confirmationModalStore.isModalShow"
    @on-hide="confirmationModalStore.hideModal()"
  >
    <template #content>
      <div class="text-container">
        <h4>{{ $t("wantContinue") }}</h4>
        <p>
          {{ $t("securityBackMessage") }}
        </p>
      </div>
      <div class="buttons-container">
        <ButtonComponent
          :title="$t('cancel')"
          @click.stop="confirmationModalStore.hideModal()"
          :dark="true"
          class="send-button"
        ></ButtonComponent>
        <ButtonComponent
          :title="$t('continue')"
          @click.stop="confirm"
          :dark="true"
          class="send-button"
        ></ButtonComponent>
      </div>
    </template>
  </ModalComponent>
</template>

<script setup lang="ts">
import { useConfirmationModalStore } from "@/stores/confirmationModal";
import { useNavigatorStore } from "@/stores/navigator";
import ButtonComponent from "@/components/ButtonComponent.vue";
import { useConfigurationStore } from "@/stores/configuration";
import { PageType } from "@/types/PageType";
import { defineAsyncComponent } from "vue";

const ModalComponent = defineAsyncComponent(
  () => import("@/components/ModalComponent.vue")
);

const confirmationModalStore = useConfirmationModalStore();
const navigatorStore = useNavigatorStore();
const configurationStore = useConfigurationStore();

function confirm(): void {
  if (
    navigatorStore.currentPage === PageType[PageType.Security] &&
    configurationStore.isPhotovoltaic &&
    !confirmationModalStore.pageName
  ) {
    navigatorStore.goToPage(PageType[PageType.Case]);
    confirmationModalStore.hideModal();

    return;
  }

  if (confirmationModalStore.pageName) {
    navigatorStore.goToPage(confirmationModalStore.pageName);
  } else {
    navigatorStore.goToPreviousPage();
  }

  confirmationModalStore.hideModal();
}
</script>

<style lang="scss">
#confirmationModal {
  .modal-body {
    padding: 20px;

    .text-container {
      margin-bottom: 20px;

      h4 {
        margin-bottom: 20px;
      }

      p {
        color: $gray;
        margin: 0;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
</style>
