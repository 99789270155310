import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useAccessoriesInfoModalStore = defineStore(
  "accessoriesInfoModalStore",
  () => {
    // region Properties
    const _showModal = ref(false);
    // endregion

    // region Getters
    const isModalShow = computed(() => _showModal.value);

    // endregion

    // region Actions
    function showModal(): void {
      _showModal.value = true;
    }

    function hideModal(): void {
      _showModal.value = false;
    }
    // endregion

    return {
      showModal,
      hideModal,
      isModalShow,
    };
  }
);
