<template>
  <header
    id="header"
    :class="[
      { 'not-switchboard-page-mobile': !isSwitchboardPage },
      { hidden: mobileStore.isHeaderHidden },
      { 'is-sub-page': isSubPage },
    ]"
  >
    <b-navbar class="custom-navbar">
      <div
        class="wrapper"
        :class="{ 'not-switchboard-page': !isSwitchboardPage }"
      >
        <div
          class="logo-and-name border-dark"
          @click="goToHomePage"
        >
          <div
            v-once
            class="navbar-brand"
          >
            <img
              class="logo-img"
              src="@/assets/img/logo.svg"
              alt="logo dr volt"
              width="292"
              height="16"
            />
            <img
              class="logo-small"
              src="@/assets/img/logo_small.svg"
              alt="logo dr volt"
              width="59"
              height="26"
            />
          </div>
          <h5 class="configurator-text text-body2">
            {{ $t("configurator") }}
          </h5>

          <PriceInfo v-if="!isSubPage && !isSummaryPage"></PriceInfo>
          <div id="logo-down-info"></div>
        </div>

        <template v-if="!isSubPage">
          <LanguageSelect v-if="isSwitchboardPage"></LanguageSelect>
          <template v-else>
            <NavigatorBreadcrumbs></NavigatorBreadcrumbs>
            <PriceInfo v-if="!isMobile && !isSummaryPage"></PriceInfo>
          </template>
        </template>
      </div>
    </b-navbar>
  </header>
</template>

<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
  onMounted,
} from "vue";
import LanguageSelect from "./HeaderComponents/LanguageSelect.vue";
import { useNavigatorStore } from "@/stores/navigator";
import { useMobileStore } from "@/stores/mobile";
import { PageType } from "@/types/PageType";
import { useRoute } from "vue-router";
import { useConfirmationModalStore } from "@/stores/confirmationModal";
import { BNavbar } from "bootstrap-vue-3";

const PriceInfo = defineAsyncComponent(
  () => import("./HeaderComponents/PriceInfo.vue")
);

const NavigatorBreadcrumbs = defineAsyncComponent(
  () => import("./HeaderComponents/NavigatorBreadcrumbs.vue")
);

const navigatorStore = useNavigatorStore();
const mobileStore = useMobileStore();
const confirmationModalStore = useConfirmationModalStore();
const route = useRoute();

const isSwitchboardPage = computed((): boolean => navigatorStore.pageId === 0);
const isSubPage = computed((): boolean => navigatorStore.isSubPage());
const isMobile = computed((): boolean => mobileStore.isMobile);
const isSummaryPage = computed((): boolean => navigatorStore.pageId == 5);

function goToHomePage(): void {
  if (
    PageType[PageType.ArrayElements] === route.name ||
    PageType[PageType.Security] === route.name ||
    PageType[PageType.Accessories] === route.name ||
    PageType[PageType.Summary] === route.name
  ) {
    confirmationModalStore.showModal(PageType[PageType.SwitchboardCategories]);
  } else {
    navigatorStore.goToPage(PageType[PageType.SwitchboardCategories]);
  }
}

onMounted(() => {
  mobileStore.watchHideHeader();
});

onBeforeUnmount(() => {
  mobileStore.stopWatchHideHeader();
});
</script>

<style lang="scss">
header {
  height: $headerHeight;
  max-width: $maxContentWidth;
  transition: height 0.3s;

  .custom-navbar {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: 0;

    .container-fluid {
      padding: 0;
      height: 100%;

      .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        height: 100%;

        &.not-switchboard-page {
          justify-content: flex-start;
        }

        .logo-and-name {
          display: flex;
          align-items: flex-end;
          padding-top: 40px;
          padding-bottom: 14px;
          position: relative;
          cursor: pointer;

          #logo-down-info {
            position: absolute;
            font-size: 13px;
            line-height: 1;
            right: 0;
            bottom: -33px;
          }

          .header-price-info {
            display: none;
          }

          .navbar-brand {
            display: flex;
            padding: 0;
            height: 22px;
            margin-right: 20px;

            .logo-img {
              height: 100%;
              max-width: 100%;
              object-fit: contain;
            }

            .logo-small {
              display: none;
            }
          }

          .configurator-text {
            margin: 0;
            line-height: 1;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .custom-navbar {
      .container-fluid {
        .wrapper {
          &.not-switchboard-page {
            justify-content: space-between;
          }
        }
      }
    }
  }

  @media (max-width: 1000px) {
    .custom-navbar {
      .container-fluid {
        .wrapper {
          .logo-and-name {
            .navbar-brand {
              margin-right: 0;
            }

            .configurator-text {
              display: none;
            }

            #logo-down-info {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    height: $headerMobileHeight;

    &.not-switchboard-page-mobile {
      height: $headerNotSwitchboardPageMobileHeight;

      &.hidden {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }
    }

    .custom-navbar {
      .container-fluid {
        .wrapper {
          &.not-switchboard-page {
            justify-content: flex-start;
            flex-direction: column;

            .logo-and-name {
              justify-content: space-between;
              align-items: center;
              width: 100%;
              padding: 15px 0;

              .header-price-info {
                display: flex;
              }

              .navbar-brand {
                height: 32px;

                .logo-img {
                  display: none;
                }

                .logo-small {
                  display: block;
                  height: 100%;
                  padding-right: 27px;
                }
              }
            }
          }

          .logo-and-name {
            padding: 20px 10px 20px 0;

            .navbar-brand {
              height: 22px;
            }
          }
        }
      }
    }
  }
}

.is-sub-page {
  display: flex;
  justify-content: center;

  .logo-img {
    display: block !important;
  }

  .logo-small {
    display: none !important;
  }
}
</style>
