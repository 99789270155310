<template>
  <footer :class="{ 'is-sub-page': isSubPage }">
    <div class="wrapper">
      <div
        v-once
        class="brand-container"
      >
        <span class="brand text-body2">
          © {{ getYear() }} DOKTORVOLT®
          <span class="version">v.{{ appVersion }}</span>
        </span>
      </div>
      <div class="link-container">
        <RouterLink
          class="text-body2"
          :to="`/${locale}/regulations`"
          >{{ $t("regulations") }}
        </RouterLink>
        <RouterLink
          class="text-body2"
          :to="`/${locale}/privacy-policy`"
          >{{ $t("privacyPolicy") }}
        </RouterLink>
      </div>
    </div>
    <div class="dh-container">
      <a
        href="https://digitalholding.pl"
        class="dh-link"
        target="_blank"
        rel="nofollow"
      >
        <img
          src="@/assets/img/logo_dh.svg"
          width="95"
          height="16"
          alt="Digital Holding"
        />
      </a>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useNavigatorStore } from "@/stores/navigator";
import { computed } from "vue";
import { version } from "../../../package.json";
import { useLocaleStore } from "@/stores/locale";

const appVersion = version;

const localeStore = useLocaleStore();
const navigatorStore = useNavigatorStore();
const isSubPage = computed((): boolean => navigatorStore.isSubPage());

const locale = computed((): string => {
  return localeStore.locale;
});

function getYear(): Number {
  return new Date().getFullYear();
}
</script>

<style scoped lang="scss">
footer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $maxContentWidth;
  height: $footerHeight;
  z-index: 2;

  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .brand-container {
      height: 100%;
    }

    .link-container {
      .text-body2 {
        &:last-child {
          margin: 0;
        }
      }
    }

    .text-body2 {
      margin-right: 20px;

      .version {
        padding-left: 10px;
      }
    }
  }

  .collapse,
  .collapsing {
    visibility: hidden;
    pointer-events: none;
    display: none;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    .dh-container {
      display: flex;
      justify-content: center;
      width: 100%;

      a {
        img {
          height: 12px;
        }
      }
    }

    .wrapper {
      justify-content: space-between;
      margin-top: 5px;

      .text-body2 {
        font-size: 10px;
        line-height: 120%;
      }
    }

    .collapsing {
      display: block;
      visibility: visible;
      pointer-events: auto;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    .collapse {
      display: block;
      visibility: visible;
      pointer-events: auto;
      position: fixed;
      top: 100%;
      left: 0;
      width: 100%;

      .card {
        border-radius: 20px 20px 0 0;
      }

      &.show {
        top: unset;
        bottom: 0;
      }
    }
  }

  @media (max-width: 380px) {
    .dh-container {
      height: 13px;
      margin-bottom: 2px;

      a {
        display: flex;
      }
    }

    .wrapper {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: space-between;

      .link-container {
        line-height: 1.2;
      }

      .brand-container {
        line-height: 1;

        .text-body2 {
          margin: 0;
        }
      }
    }
  }
}

.is-sub-page .wrapper {
  display: flex;
  justify-content: center;
}
</style>
