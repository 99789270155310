<script setup lang="ts">
import { computed, onBeforeMount, onMounted, onUnmounted } from "vue";
import { RouterView } from "vue-router";
import HeaderComponent from "./components/Layout/HeaderComponent.vue";
import FooterComponent from "./components/Layout/FooterComponent.vue";
import { useNavigatorStore } from "@/stores/navigator";
import { useMobileStore } from "@/stores/mobile";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import { BContainer } from "bootstrap-vue-3";
import { useConfigurationStore } from "@/stores/configuration";

const configurationStore = useConfigurationStore();
const navigatorStore = useNavigatorStore();
const mobileStore = useMobileStore();

const isSwitchboardPage = computed(() => navigatorStore.pageId === 0);

mobileStore.watchIsMobileScreen();

function onDocumentClick() {
  configurationStore.setSelectedBoardSet(null);
}

onBeforeMount(() => {
  mobileStore.onResize();
});

onMounted(() => {
  document.addEventListener("click", onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener("click", onDocumentClick);
});
</script>

<template>
  <BContainer>
    <HeaderComponent></HeaderComponent>
    <main
      :class="[
        { 'not-switchboard-page-mobile': !isSwitchboardPage },
        { 'header-hidden': mobileStore.isHeaderHidden },
      ]"
    >
      <RouterView />
    </main>
    <ConfirmationModal></ConfirmationModal>
    <FooterComponent></FooterComponent>
  </BContainer>
</template>

<style lang="scss">
html {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  display: flex;
  justify-content: center;
  margin: 0;
  background: $background;
  max-width: 100vw;
  width: 100vw;
  font-family: Lato, sans-serif;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(white, 0.2);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: $dark;
    width: 6px;
  }
}

.v-toast {
  z-index: 10000;
}

#app {
  width: 100%;
  color: $dark;
  max-width: $maxContentWidth;
}

main {
  position: relative;
  padding: 0;
  min-height: calc(100vh - $headerHeight - $footerHeight);

  @media (max-width: 768px) {
    padding: 0;
    min-height: calc(100vh - $headerMobileHeight - $footerHeight);

    &.not-switchboard-page-mobile {
      min-height: calc(
        100vh - $headerNotSwitchboardPageMobileHeight - $footerHeight
      );

      &.header-hidden {
        min-height: calc(100vh - $footerHeight);
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
