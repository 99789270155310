import axiosClient from "../http-common";
import Functions from "@/plugins/Functions";
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type ISecurityProducers from "@/types/ISecurityProducers";

export class SecurityElementsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axiosClient;
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<[any] | [any, T, any]> {
    try {
      const { data, headers } = await this.axiosInstance.request<T>(config);
      return [null, data, { headers }];
    } catch (error: Error | any) {
      console.error(error);

      return [error];
    }
  }

  // Typy:
  // 1 - elementy tablicowe
  // 2 - zabezpieczenia
  async getProducersWithElementsSecurities(
    caseId: number,
    compatibleFuses: { [key: number]: number },
    locale: string
  ): Promise<[any] | [any, ISecurityProducers, any]> {
    let filterParams = "";
    let index = 0;

    for (const [elementId, quantity] of Object.entries(compatibleFuses)) {
      filterParams += `&board_element[${index}][id]=${elementId}&board_element[${index}][quantity]=${quantity}`;

      index++;
    }

    const params = {
      locale: locale ? locale : "pl",
    };

    const paramsString = Functions.buildUrlParams(params);

    return this.axiosCall<ISecurityProducers>({
      method: "get",
      url: `/switchboards/${caseId}/compatible-fuses${paramsString}${filterParams}`,
    });
  }
}

export const securityElementsAPI = new SecurityElementsAPIService();
