import { createRouter, createWebHistory } from "vue-router";
import { useModalsStore } from "@/stores/modals";
import Functions from "@/plugins/Functions";
import { useNavigatorStore } from "@/stores/navigator";
import { useMobileStore } from "@/stores/mobile";
import { defineAsyncComponent } from "vue";
import { i18n } from "@/main";
import { useLocaleStore } from "@/stores/locale";

const CaseView = defineAsyncComponent(() => import("@/views//CaseView.vue"));

function getBrowserLocale() {
  const navigatorLocale = navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language;

  if (!navigatorLocale) {
    return "pl";
  }

  const trimmedLocale = navigatorLocale.trim().split(/-|_/)[0];

  if (
    i18n.global.availableLocales.includes(trimmedLocale as "pl" | "en" | "de")
  ) {
    return trimmedLocale;
  }

  return "pl";
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: () => `/${getBrowserLocale()}`,
    },
    {
      path: "/:locale",
      component: () => import("@/components/LocaleLayout.vue"),
      children: [
        {
          path: "/:locale",
          name: "SwitchboardCategories",
          component: () => import("@/views/SwitchboardCategoriesView.vue"),
        },
        {
          path: "/:locale",
          name: "Case",
          component: CaseView,
        },
        {
          path: "/:locale",
          name: "ArrayElements",
          component: () => import("@/views/ArrayElementsView.vue"),
        },
        {
          path: "/:locale",
          name: "Security",
          component: () => import("@/views/SecurityView.vue"),
        },
        {
          path: "/:locale",
          name: "Accessories",
          component: () => import("@/views/AccessoriesView.vue"),
        },
        {
          path: "/:locale",
          name: "Summary",
          component: () => import("@/views/SummaryView.vue"),
        },
        {
          path: "/:locale/privacy-policy",
          name: "PrivacyPolicy",
          component: () => import("@/views/PrivacyPolicyView.vue"),
        },
        {
          path: "/:locale/regulations",
          name: "Regulations",
          component: () => import("@/views/RegulationsView.vue"),
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const localeStore = useLocaleStore();

  const locale = to.params.locale as string;

  // Ustaw język dla i18n
  if (i18n.global.locale.value !== locale) {
    (i18n.global.locale.value as string) = locale;
    localeStore.setLocale(locale);
  }

  const dropdowns = document.querySelectorAll('ul[role="listbox"]');
  const modalsStore = useModalsStore();
  const navigatorStore = useNavigatorStore();
  const mobileStore = useMobileStore();

  dropdowns.forEach((dropdown) => dropdown.remove());

  if (to.name === "PrivacyPolicy" || to.name === "Regulations") {
    navigatorStore.setIsInfoPage(true);
  } else {
    navigatorStore.setIsInfoPage(false);
  }

  Functions.scrollToTop();
  modalsStore.hideAllModals();
  mobileStore.setIsModalOpen(false);

  next();
});

export default router;
