import { useToast } from "vue-toast-notification";
import type { ToastType } from "@/types/ToastType";

const $toast = useToast();

export function toast(message: string, type: ToastType): void {
  $toast.open({
    message: message,
    type: type,
    duration: 3000,
    dismissible: true,
    position: "top-right",
  });
}
