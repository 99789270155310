import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useNavigatorStore } from "@/stores/navigator";

export const useMobileStore = defineStore("mobile", () => {
  // region Properties
  const _isMobile = ref(false);
  const _isHtmlScrolled = ref(false);
  const _windowHeight = ref(
    window.visualViewport ? window.visualViewport.height : window.innerHeight
  );
  const _isModalOpen = ref(false);
  // endregion

  // region Getters

  const navigatorStore = useNavigatorStore();

  const isModalOpen = computed((): boolean => _isModalOpen.value);
  const windowHeight = computed((): number => _windowHeight.value);

  const isMobile = computed((): boolean => _isMobile.value);
  const isHeaderHidden = computed(() => {
    return (
      _isHtmlScrolled.value &&
      (navigatorStore.pageId === 2 || navigatorStore.pageId === 3)
    );
  });
  // endregion

  // region Actions
  function setIsModalOpen(state: boolean) {
    _isModalOpen.value = state;
  }

  function onResize(): void {
    _windowHeight.value = window.visualViewport.height;
    _isMobile.value = window.innerWidth <= 768;
  }

  function watchIsMobileScreen() {
    window.addEventListener("resize", onResize, { passive: true });
    window.visualViewport.addEventListener("resize", onResize);
  }

  function watchHideHeader() {
    window.addEventListener("scroll", checkScroll);
  }

  function stopWatchHideHeader() {
    window.removeEventListener("scroll", checkScroll);
  }

  function checkScroll() {
    const html = document.querySelector("html") as HTMLHtmlElement;
    const header = document.querySelector("header") as HTMLHeadElement;

    if (html && header) {
      const visualHeight = window.visualViewport
        ? window.visualViewport.height
        : window.innerHeight;

      _isHtmlScrolled.value =
        html.scrollTop > 0 &&
        html.offsetHeight - visualHeight - header.offsetHeight > 0;
    }
  }

  function stopWatchIsMobileScreen() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", onResize);
    window.visualViewport.removeEventListener("resize", onResize);
  }
  // endregion

  return {
    onResize,
    isMobile,
    watchIsMobileScreen,
    watchHideHeader,
    stopWatchHideHeader,
    isHeaderHidden,
    stopWatchIsMobileScreen,
    windowHeight,
    isModalOpen,
    setIsModalOpen,
  };
});
